// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pop-rectangle-div {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
  background-color: var(--color-darkturquoise);
  width: 212px;
  height: 60px;
}
.pop-contact-us {
  position: absolute;
  top: 18px;
  left: 53px;
  letter-spacing: -0.02em;
  line-height: 120%;
}
.pop-rectangle-parent {
  width: 212px;
  height: 60px;
  cursor: pointer;
  text-align: left;
  font-size: 20px;
  color: var(--color-white);
  font-family: var(--font-inter);
}
`, "",{"version":3,"sources":["webpack://./src/components/Group.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,kBAAkB;EAClB,4CAA4C;EAC5C,YAAY;EACZ,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,UAAU;EACV,uBAAuB;EACvB,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,eAAe;EACf,yBAAyB;EACzB,8BAA8B;AAChC","sourcesContent":[".pop-rectangle-div {\n  position: absolute;\n  top: 0;\n  left: 0;\n  border-radius: 5px;\n  background-color: var(--color-darkturquoise);\n  width: 212px;\n  height: 60px;\n}\n.pop-contact-us {\n  position: absolute;\n  top: 18px;\n  left: 53px;\n  letter-spacing: -0.02em;\n  line-height: 120%;\n}\n.pop-rectangle-parent {\n  width: 212px;\n  height: 60px;\n  cursor: pointer;\n  text-align: left;\n  font-size: 20px;\n  color: var(--color-white);\n  font-family: var(--font-inter);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
