import React, { useState, useEffect } from 'react';
import jsPDF from 'jspdf';

const Download = () => {
  const [siteInfo, setSiteInfo] = useState(null);
  const [gFloorInfo, setGFloorInfo] = useState(null);
  const [numberOfFloors, setNumberOfFloors] = useState(null);
  const [floorDetails, setFloorDetails] = useState([]);
  const [additionalInfo, setAdditionalInfo] = useState(null);

  useEffect(() => {
    const storedResponse = localStorage.getItem("apiResponse");
    if (storedResponse) {
      const data = JSON.parse(storedResponse);

      setSiteInfo({
        sqFt: data.total_built_up_area,
        carpetArea: data.carpet_area,
        heightRestriction: "", // Assuming placeholder
        totalBuiltUpArea: data.total_built_up_area,
        fsi: data.FSI,
        fsiHeight: "", // Assuming placeholder
      });

      setGFloorInfo({
        opdArea: data.ground_floor_area[0].opd[0],
        diagnosticArea: data.ground_floor_area[0].diagnostic,
        circulationArea: data.ground_floor_area[0].circulation,
        pharmacyArea: data.ground_floor_area[0].pharmacy,
        receptionArea: data.ground_floor_area[0].reception,
        totalGroundFloorArea: data.ground_floor_area[0].total_area,
        casualtyArea: data.ground_floor_area[0].casualty[0],
      });

      setNumberOfFloors(data.no_of_floors);

      const updatedDetails = data.floors_area.map((floor, index) => ({
        floorNumber: index + 1,
        floorArea: floor.total_area,
        floorRestriction: "", // Assuming placeholder
        totalFloorArea: floor.total_area,
        totalAreaPrivateRooms: floor.private[0],
        numBedsPrivate: floor.private[1],
        totalAreaSemiPrivateRooms: floor.semi_private[0],
        numBedsSemiPrivate: floor.semi_private[1],
        circulationArea: floor.circulation,
      }));
      setFloorDetails(updatedDetails);

      setAdditionalInfo({
        icu: {
          totalArea: data.ICU.total_area,
          totalBeds: data.ICU.total_beds,
        },
        isolation: {
          totalArea: data.isolation.total_area,
          totalBeds: data.isolation.total_beds,
        },
        nursingStation: data.nursing_station,
        circulation: data.circulation,
        miscellaneous: data.miscelleneous,
        semiIcu: {
          totalArea: data.semi_icu.total_area,
          totalBeds: data.semi_icu.total_beds,
        },
      });
    }
  }, []);

  const downloadPDF = () => {
    if (!siteInfo || !gFloorInfo || !numberOfFloors || floorDetails.length === 0 || !additionalInfo) {
        alert('No data to download');
        return;
    }

    const doc = new jsPDF();
    let yPosition = 10; // Initial Y position
    
    const checkAndAddPage = () => {
      if (yPosition > 270) { // Check if the yPosition exceeds the page height
          doc.addPage();
          
          yPosition = 15; // Reset yPosition for the new page
      }
  };

    doc.text('Hospital Information', 80, yPosition);
    yPosition += 21;

    doc.text('Site Information:', 10, yPosition);
    yPosition += 10;
    doc.text(`  Square Feet: ${siteInfo.sqFt}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Carpet Area: ${siteInfo.carpetArea}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Total Built-Up Area: ${siteInfo.totalBuiltUpArea}`, 10, yPosition);
    yPosition += 15;

    doc.text('Floor Information:', 10, yPosition);
    yPosition += 12;

    doc.text('Ground Floor Information:', 10, yPosition);
    yPosition += 10;
    doc.text(`  OPD Area: ${gFloorInfo.opdArea}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Diagnostic Area: ${gFloorInfo.diagnosticArea}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Circulation Area: ${gFloorInfo.circulationArea}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Pharmacy Area: ${gFloorInfo.pharmacyArea}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Reception Area: ${gFloorInfo.receptionArea}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Total Ground Floor Area: ${gFloorInfo.totalGroundFloorArea}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Casualty Area: ${gFloorInfo.casualtyArea}`, 10, yPosition);
    yPosition += 16;

    floorDetails.forEach((floor, index) => {
        checkAndAddPage()
        doc.text(`Floor ${floor.floorNumber}:`, 10, yPosition);
        yPosition += 10;
        doc.text(`  Total Floor Area: ${floor.floorArea}`, 10, yPosition);
        yPosition += 10;
        doc.text(`  Private Rooms Area: ${floor.totalAreaPrivateRooms}`, 10, yPosition);
        yPosition += 10;
        doc.text(`  Private Room Beds: ${floor.numBedsPrivate}`, 10, yPosition);
        yPosition += 10;
        checkAndAddPage()
        doc.text(`  Semi-Private Rooms Area: ${floor.totalAreaSemiPrivateRooms}`, 10, yPosition);
        yPosition += 10;
        checkAndAddPage()
        doc.text(`  Semi-Private Room Beds: ${floor.numBedsSemiPrivate}`, 10, yPosition);
        yPosition += 10;
        checkAndAddPage()
        doc.text(`  Circulation Area: ${floor.circulationArea}`, 10, yPosition);
        yPosition += 16; // Add space before the next floor
    });

    checkAndAddPage()

    doc.text('ICU Information:', 10, yPosition);
    yPosition += 10;
    doc.text(`  ICU Area: ${additionalInfo.icu.totalArea}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  ICU Beds: ${additionalInfo.icu.totalBeds}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Isolation Area: ${additionalInfo.isolation.totalArea}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Isolation Beds: ${additionalInfo.isolation.totalBeds}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Nursing Station: ${additionalInfo.nursingStation}`, 10, yPosition);
    yPosition += 10;
    doc.text(`  Circulation: ${additionalInfo.circulation}`, 10, yPosition);
    yPosition += 10;
    checkAndAddPage()
    doc.text(`  Semi-ICU Area: ${additionalInfo.semiIcu.totalArea}`, 10, yPosition);
    yPosition += 10;
    checkAndAddPage()
    doc.text(`  Semi-ICU Beds: ${additionalInfo.semiIcu.totalBeds}`, 10, yPosition);
    checkAndAddPage()
    doc.save('hospital-data.pdf');
};



  return (
    <div >
      <button
        onClick={downloadPDF}
        style={{
          padding: '10px 20px',
          backgroundColor: '#28a745',
          color: '#fff',
          borderRadius: '5px',
          border: 'none',
          cursor: 'pointer',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
        }}
      >
        Download  Data
      </button>
    </div>
  );
};

export default Download;
