// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-slider-container {
    position: absolute; /* Position the slider absolutely within its container */
    display: flex;
    transition: transform 0.5s ease-in-out;
    top: 37vh; /* Position the slider at the top of its container */
    left: 0; /* Position the slider at the left of its container */
    width: 100%;
    border-radius: 15px; /* Make the slider fill the container width */
  }
  
  .image-slider {
    position: absolute; /* Position the slider absolutely within its container */
    display: flex;
    transition: transform 0.5s ease-in-out;
    top: 0; /* Position the slider at the top of its container */
    left: 0; /* Position the slider at the left of its container */
  }
  
  
  .image-slider img {
    width: 100%;
    height: auto;
    border-radius: 20px; /* Make the slider fill the container width */

  }
  `, "",{"version":3,"sources":["webpack://./src/pages/ImageSlider.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB,EAAE,wDAAwD;IAC5E,aAAa;IACb,sCAAsC;IACtC,SAAS,EAAE,oDAAoD;IAC/D,OAAO,EAAE,qDAAqD;IAC9D,WAAW;IACX,mBAAmB,EAAE,6CAA6C;EACpE;;EAEA;IACE,kBAAkB,EAAE,wDAAwD;IAC5E,aAAa;IACb,sCAAsC;IACtC,MAAM,EAAE,oDAAoD;IAC5D,OAAO,EAAE,qDAAqD;EAChE;;;EAGA;IACE,WAAW;IACX,YAAY;IACZ,mBAAmB,EAAE,6CAA6C;;EAEpE","sourcesContent":[".image-slider-container {\n    position: absolute; /* Position the slider absolutely within its container */\n    display: flex;\n    transition: transform 0.5s ease-in-out;\n    top: 37vh; /* Position the slider at the top of its container */\n    left: 0; /* Position the slider at the left of its container */\n    width: 100%;\n    border-radius: 15px; /* Make the slider fill the container width */\n  }\n  \n  .image-slider {\n    position: absolute; /* Position the slider absolutely within its container */\n    display: flex;\n    transition: transform 0.5s ease-in-out;\n    top: 0; /* Position the slider at the top of its container */\n    left: 0; /* Position the slider at the left of its container */\n  }\n  \n  \n  .image-slider img {\n    width: 100%;\n    height: auto;\n    border-radius: 20px; /* Make the slider fill the container width */\n\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
