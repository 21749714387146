// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Arial, sans-serif;
}

.slider {
  width: 90%;
  max-width: 1200px;
  margin: 50px auto;
  position: relative;
  overflow: hidden;
  top: 5rem;
}

.slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slides img {
  width: 100%;
  height: auto;
}

.navigation {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: translateY(-50%);
}

.navigation .prev {
  margin-left: 0rem;
}

.navigation .next {
  margin-left: 63rem;
}

.navigation button {
  background-color: #3cd0ce;
  border: none;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.navigation button:hover {
  background-color:#3cd0ce;
}

@media only screen and (max-width: 758px){

  .slider {
    width: 80%;
    max-width: 1200px;
    margin: 50px auto;
    position: relative;
    overflow: hidden;
    top: 4rem;
  }
  .navigation .prev {
    margin-left: 0rem;
  }
  
  .navigation .next {
    margin-left: 55rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ImageSlider1.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,sCAAsC;AACxC;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;;EAEE;IACE,UAAU;IACV,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,SAAS;EACX;EACA;IACE,iBAAiB;EACnB;;EAEA;IACE,kBAAkB;EACpB;AACF","sourcesContent":["body {\n  font-family: Arial, sans-serif;\n}\n\n.slider {\n  width: 90%;\n  max-width: 1200px;\n  margin: 50px auto;\n  position: relative;\n  overflow: hidden;\n  top: 5rem;\n}\n\n.slides {\n  display: flex;\n  transition: transform 0.5s ease-in-out;\n}\n\n.slides img {\n  width: 100%;\n  height: auto;\n}\n\n.navigation {\n  position: absolute;\n  top: 50%;\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  transform: translateY(-50%);\n}\n\n.navigation .prev {\n  margin-left: 0rem;\n}\n\n.navigation .next {\n  margin-left: 63rem;\n}\n\n.navigation button {\n  background-color: #3cd0ce;\n  border: none;\n  color: white;\n  padding: 10px;\n  cursor: pointer;\n}\n\n.navigation button:hover {\n  background-color:#3cd0ce;\n}\n\n@media only screen and (max-width: 758px){\n\n  .slider {\n    width: 80%;\n    max-width: 1200px;\n    margin: 50px auto;\n    position: relative;\n    overflow: hidden;\n    top: 4rem;\n  }\n  .navigation .prev {\n    margin-left: 0rem;\n  }\n  \n  .navigation .next {\n    margin-left: 55rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
